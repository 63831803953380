import React from 'react';
import Footer from './Footer';
import './App.css';
import BookingView from './BookingView';
import logo from './tusbrake_volleyball_CMYK.png';



function App() {
  return (
    <div className="App">
      <div class="heading-container">
        <h1>Beachzeiten 2024</h1>
        <div class="h1-sub">Stand: 29.04.2024</div>
        <img src={logo} alt="Logo" className="logo" />
      </div>
      <BookingView />
      <Footer />
    </div>
  );
}

export default App;
