// Footer.js
import React from 'react';
import './Footer.css'; // Optional: CSS-Datei für die Fußzeile

const Footer = () => {
    return (
        <footer className="app-footer">
            <p>|| Copyright&copy; 2024 || Florian R&uuml;gge ||</p>
        </footer>
    );
};

export default Footer;
