import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// StrictMode ist ein Werkzeug für die Hervorhebung potenzieller Probleme in einer Anwendung.
// Ähnlich wie Fragment, hat StrictMode keine Auswirkungen auf die UI, sondern aktiviert zusätzliche Prüfungen und Warnungen für seine Nachkommen.
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// Wenn Sie mit der Leistungsanalyse Ihrer App beginnen möchten, passen Sie den
// import an, um reportWebVitals(console.log) oder senden Sie an einen Endpunkt.
// Erfahren Sie mehr: https://bit.ly/CRA-vitals
reportWebVitals();
