// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Footer.css */
.app-footer {
    text-align: center;
    padding: 20px 0;
    margin-top: 20px;
    background-color: #f0f0f000;
    color: #333;
}`, "",{"version":3,"sources":["webpack://./src/Footer.css"],"names":[],"mappings":"AAAA,eAAe;AACf;IACI,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,2BAA2B;IAC3B,WAAW;AACf","sourcesContent":["/* Footer.css */\r\n.app-footer {\r\n    text-align: center;\r\n    padding: 20px 0;\r\n    margin-top: 20px;\r\n    background-color: #f0f0f000;\r\n    color: #333;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
