import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import './BookingView.css'; // Stellen Sie sicher, dass Sie Ihre CSS-Datei entsprechend anpassen
import logo from './tusbrake_volleyball_CMYK.png';

const BookingView = () => {
    const [bookingsByWeekdayAndField, setBookingsByWeekdayAndField] = useState({ 1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: [] });
    const [currentMessageField, setCurrentMessage] = useState(""); // Zustand für aktuelle Meldungen
    const [filterValue, setFilterValue] = useState(""); // Zustand für den Filterwert
    const [teamNames, setTeamNames] = useState([]); // Zustand für die sortierten Teamnamen

    // Funktion zum Markieren von verbundenen Buchungen
    function markConnectedBookings(bookings) {
        const bookingEntries = Object.entries(bookings).flatMap(([weekday, fields]) =>
            Object.entries(fields).flatMap(([field, fieldBookings]) =>
                fieldBookings.map(booking => ({ ...booking, weekday, field: parseInt(field, 10) }))
            )
        );

        const connectedBookings = bookingEntries.reduce((acc, booking) => {
            const key = `${booking.weekday}-${booking.Mannschaft}-${booking.Startzeit}-${booking.Endzeit}`;
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(booking.field);
            return acc;
        }, {});

        return bookingEntries.reduce((acc, booking) => {
            const key = `${booking.weekday}-${booking.Mannschaft}-${booking.Startzeit}-${booking.Endzeit}`;
            const fields = connectedBookings[key];
            if (fields.length > 1) {
                booking.connectedFields = fields;
            }
            if (!acc[booking.weekday]) acc[booking.weekday] = {};
            if (!acc[booking.weekday][booking.field]) acc[booking.weekday][booking.field] = [];
            acc[booking.weekday][booking.field].push(booking);
            return acc;
        }, {});
    }

    // useEffect-Hook zum Laden der Buchungsdaten
    useEffect(() => {
        const csvFilePath = process.env.PUBLIC_URL + '/buchungen.csv';
        fetch(csvFilePath)
            .then(response => response.text())
            .then(csvText => {
                Papa.parse(csvText, {
                    header: true,
                    skipEmptyLines: true,
                    complete: (result) => {
                        const sortedBookings = { 1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: [] };
                        result.data.forEach(booking => {
                            const weekday = booking.Wochentag; // Annahme: Spalte 1 enthält 'Wochentag'
                            const field = booking.Feld;
                            if (!sortedBookings[weekday][field]) {
                                sortedBookings[weekday][field] = [];
                            }
                            sortedBookings[weekday][field].push(booking);
                        });
                        const markedBookings = markConnectedBookings(sortedBookings);
                        setBookingsByWeekdayAndField(markedBookings);

                        // Sortiere die Teamnamen alphabetisch
                        const sortedTeamNames = Array.from(new Set(Object.values(sortedBookings).flatMap(fields => Object.values(fields).flatMap(booking => booking.map(b => b.Mannschaft))))).sort();
                        setTeamNames(sortedTeamNames);
                    }
                });
            });
    }, []);

    // Funktion zum Filtern von Buchungen nach Mannschaft
    function filterBookingsByTeam(bookings, team) {
        if (!team) return bookings; // Wenn kein Team ausgewählt ist, zeige alle Buchungen an
        const filteredBookings = {};
        for (const weekday in bookings) {
            filteredBookings[weekday] = {};
            for (const field in bookings[weekday]) {
                filteredBookings[weekday][field] = bookings[weekday][field].filter(booking => booking.Mannschaft === team);
            }
        }
        return filteredBookings;
    }

    const filteredBookings = filterBookingsByTeam(bookingsByWeekdayAndField, filterValue); // Anwenden des Filters

    const weekdayNames = ["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sonntag"];

    return (
        <>
            <div className="filter-container">
                <span className="filter-label">Wähle dein Team:</span>
                <select value={filterValue} onChange={(e) => setFilterValue(e.target.value)}>
                    <option value="">Alle Mannschaften</option>
                    {teamNames.map((team, index) => (
                        <option key={index} value={team}>{team}</option>
                    ))}
                </select>
            </div>
            <div className="days-container">
                {Object.entries(filteredBookings).map(([weekday, fields]) => (
                    <div key={weekday} className="day-container">
                        <div className="message-containerfield">
                            <p>{currentMessageField}</p> {/* Aktuelle Meldungen */}
                        </div>
                        <h2>{weekdayNames[weekday - 1]}</h2>
                        <div className="fields-container">
                            {Object.entries(fields).map(([field, bookings]) => (
                                <div key={field} className={`field-column ${bookings.some(booking => booking.connectedFields) ? 'connected' : ''}`}>
                                    <h3>Feld {field}</h3>
                                    {bookings.map((booking, index) => (
                                        <div key={index} className={`booking-block ${booking.connectedFields ? 'connected-booking' : ''}`}>
                                            <div className="team-name">{booking.Mannschaft}</div>
                                            <div className="time">Zeit:</div><div className="time-value"> {booking.Startzeit} - {booking.Endzeit}</div>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default BookingView;

